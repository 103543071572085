import { useEffect, useState } from 'react';
import classNames from 'classnames';

import FileUploader, { AcceptFileType } from '../../../../../../common/FileUploader';
import SecureBodyPhoto from '../../../../SecureBodyPhoto';

import ButtonBase from '@mui/material/ButtonBase';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';

import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';

import makeGStyles from '../../../../../../../styles/makeGStyles';
import { useGDispatch, useGSelector } from '../../../../../../../types';
import { getBlobFromData } from '../../../../../../../services/doc.service';
import { GatherCaseUX, PhotoTransformationsType } from '../../../../../../../shared/types';
import PhotoCropper from '../../../../../../profileImage/PhotoCropper';
import { getDataURIFromFile, getIntercomTargetProp } from '../../../../../../../services';
import {
    uploadCaseIdPhoto,
} from '../../../../../../../actions/CaseIdPhotos.action';
import { log } from '../../../../../../../logger';

const useStyles = makeGStyles(theme => ({
    root: {
        width: 'fit-content',
        overflowY: 'unset',
        overflowX: 'auto',
        columnGap: '20px',
        perspective: 600,
        WebkitPerspective: 600,
        '&$list': {
            padding: '16px 16px 24px !important',
        }
    },
    takePhotoBtn: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: theme.palette.secondary.main,
        border: '1px dashed',
        borderRadius: 8,
        padding: '8px 2px',
        margin: '0 auto',
        minWidth: 172,
        maxWidth: 172,
        height: 172,
        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover'
        },
        '& svg': {
            fontSize: 48,
            padding: '16px 0'
        },
    },
    addBtn: {
        border: '1px dashed',
    },
    alignCenter: {
        alignItems: 'center'
    },
    paddingBottom8: {
        paddingBottom: '8px !important',
    },
    loadingFragment: {
        minWidth: 172,
        maxWidth: 172,
        height: 172,
        padding: '8px 2px',
        display: 'flex',
        alignItems: 'center',
    }
}), { name: 'IdPhotos' });

interface Props {
    disableAddEdit?: boolean;
    selectedCase: Pick<GatherCaseUX, 'uuid' | 'fname'> | null; // null case is for Back Office preview
    disablePhotoUpload?: boolean;
    onIsPhotoUploadedChange?: (isFileSaved: boolean) => void;
    onPhotoClick?: () => void;
    onPhotoDelete?: (photoId: number) => void;
    intercomTargetProp?: string;
    intercomTargetPropAddPhoto?: string;
}

const IdPhotos = (props: Props) => {
    const {
        disableAddEdit,
        selectedCase,
        disablePhotoUpload,
        onPhotoClick,
        onPhotoDelete,
        onIsPhotoUploadedChange,
        intercomTargetProp,
        intercomTargetPropAddPhoto,
    } = props;

    const caseUuid = selectedCase?.uuid ?? null;

    const classes = useStyles();

    const dispatch = useGDispatch();
    const photos = useGSelector(({ casesState }) => casesState.caseIdPhotos);
    const isLoading = useGSelector(({ casesState }) => casesState.caseIdPhotosLoading);

    const isBackOffice = caseUuid === null;

    const [isPhotoUploading, setIsPhotoUploading] = useState(false);
    const [isPhotoCropperOpen, setIsPhotoCropperOpen] = useState(false);
    const [imageUri, setImageUri] = useState('');
    const [filename, setFilename] = useState('');
    const [isPhotoUploaded, setIsPhotoUploaded] = useState(false);

    useEffect(() => {
        setIsPhotoUploaded(photos.length > 0);
    }, [photos]);

    useEffect(() => {
        onIsPhotoUploadedChange?.(isPhotoUploaded);
    }, [onIsPhotoUploadedChange, isPhotoUploaded]);

    const handlePhotoSave = async (files: FileList | null) => {

        if (files && files.length !== 0) {
            const dataUri = await getDataURIFromFile(files[0]);

            setImageUri(dataUri);
            setFilename(files[0].name);

            setIsPhotoCropperOpen(true);
        }
    };

    const saveNewImage = async (_: PhotoTransformationsType, newImageURI: string) => {
        if (disablePhotoUpload) {
            log.warn('Photo upload is disabled');
            return;
        }
        setIsPhotoUploading(true);
        const docToUpload = getBlobFromData(newImageURI, filename);
        if (docToUpload && !isBackOffice) {
            await dispatch(uploadCaseIdPhoto(caseUuid, docToUpload));
        }

        setIsPhotoUploading(false);
        setIsPhotoCropperOpen(false);
    };

    const loadingFragment = (
        <Grid
            item
            xs={12}
            justifyContent="center"
            className={classes.loadingFragment}
        >
            <CircularProgress
                color="primary"
                size={86}
                thickness={3}
            />
        </Grid>
    );

    const showUploader = !isLoading && !disablePhotoUpload && !disableAddEdit;
    const showLoadingSpinner = (isPhotoUploading || isLoading) && !isBackOffice;

    return (
        <Grid item={true} xs={12} className={classes.overflowXAuto}>
            <Grid
                item={true}
                className={classNames(
                    classes.alignCenter,
                    (!disablePhotoUpload || photos.length !== 0) && classes.list,
                    classes.root,
                    (disablePhotoUpload && photos.length === 0) && classes.paddingBottom8,
                )}
                {...getIntercomTargetProp(intercomTargetProp)}
            >
                {!isLoading && photos.map((photo) =>
                    <SecureBodyPhoto
                        key={photo.s3_file_id}
                        caseUuid={caseUuid}
                        photo={photo}
                        imageOverlayBottomText="ID photo hidden behind one click for your protection."
                        onDeleteClick={onPhotoDelete && !disableAddEdit
                            ? (_, s3FileId) => onPhotoDelete(s3FileId)
                            : undefined}
                        onPhotoClick={onPhotoClick}
                        size="small"
                    />
                )}

                {showLoadingSpinner && loadingFragment}

                {showUploader &&
                    <FileUploader
                        acceptTypes={[AcceptFileType.PNG, AcceptFileType.JPEG, AcceptFileType.GIF]}
                        handleSave={handlePhotoSave}
                    >
                        {(inputRef) =>
                            <>
                                {(isPhotoUploaded || isPhotoUploading) &&
                                    <ButtonBase
                                        className={classNames(
                                            classes.takePhotoBtn,
                                            classes.justifyContentCenter
                                        )}
                                        onClick={e => inputRef.click()}
                                        {...getIntercomTargetProp(intercomTargetPropAddPhoto)}
                                    >
                                        <AddAPhotoIcon color="inherit" />

                                        <span className={classes.fontSize16}>
                                            Add another ID Photo of {selectedCase?.fname}
                                        </span>

                                    </ButtonBase>
                                }

                                {!isPhotoUploaded && !isPhotoUploading &&
                                    <ButtonBase
                                        className={classNames(
                                            classes.takePhotoBtn,
                                        )}
                                        onClick={e => inputRef.click()}
                                    >

                                        <span className={classes.fontSize16}>
                                            Add ID Photo of {selectedCase?.fname}
                                        </span>

                                        <AddAPhotoIcon color="inherit" />

                                        <span className={classNames(classes.fontStyleItalic, classes.fontSize12)}>
                                            Used for secure identification.
                                            Will be deleted 7 days after WorkFlow completion.
                                        </span>
                                    </ButtonBase>
                                }
                            </>
                        }
                    </FileUploader>
                }
            </Grid>

            {!disablePhotoUpload &&
                <PhotoCropper
                    imageURI={imageUri}
                    onSaveImage={saveNewImage}
                    isDialogOpen={isPhotoCropperOpen}
                    closeDialog={() => setIsPhotoCropperOpen(false)}
                    zIndex={1330}
                />
            }
        </Grid >
    );
};

export default IdPhotos;
