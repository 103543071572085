
import { UploadFileRequest } from '../shared/types';
import * as mime from 'mime-types';
import { generateHash } from '../shared/docs/utils';
import { log } from '../logger';

export const getDataFromBlob = (blob: Blob, fileName: string = 'unknown'): Promise<UploadFileRequest | null> => {
    return new Promise<UploadFileRequest | null>((resolve) => {

        const reader = new FileReader();
        reader.addEventListener(
            'loadend',
            function() {
                const maxUploadBytesLimit = 52428800; // 50 megabytes
                const warnUploadBytesLimit = 26214400; // 25 megabytes

                let suffix: string | null = null;
                const derivedSuffix: string | false = mime.extension(blob.type);
                if (typeof derivedSuffix === 'string') {
                    suffix = derivedSuffix;
                }
                if (blob.size > maxUploadBytesLimit) {
                    log.warn('DOC FILE UPLOAD FAILED: Upload file size is greater than 50MB limit', {
                        size: blob.size,
                    });
                    return resolve(null);
                } else if (blob.size > warnUploadBytesLimit) {
                    log.warn(`DOC FILE UPLOAD WARNING: size greater than 25MB: ${blob.size}`);
                }
                if (reader.result && (reader.result instanceof ArrayBuffer)) {
                    const dataBuffer = Buffer.from(reader.result);
                    const hash = generateHash(dataBuffer);
                    const reqData: UploadFileRequest = {
                        hash,
                        name: fileName,
                        size: blob.size,
                        type: blob.type,
                        suffix: suffix,
                        filecontents: reader.result,
                    };
                    return resolve(reqData);
                }
                log.warn('FILE UPLOAD FAILED: Error computing file hash', {
                    result: reader.result,
                });
                return resolve(null);
            },
            false,
        );
        reader.readAsArrayBuffer(blob);
    });
};

export const getBlobFromData = (datUri: string, filename: string): UploadFileRequest | null => {
    const [metaData, base64data] = datUri.split(',');
    const mimeString = metaData.match(/:(.*?);/);
    if (!mimeString) {
        throw new Error('Invalid data URI');
    }

    const byteCharacters = atob(base64data);
    const byteArray = new Uint8Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteArray[i] = byteCharacters.charCodeAt(i);
    }
    // const byteArray = new Uint8Array(byteNumber);
    const blob = new Blob([byteArray], { type: mimeString[1] });
    const hash = generateHash(Buffer.from(byteArray));

    let suffix: string | null = null;
    const derivedSuffix: string | false = mime.extension(blob.type);
    if (typeof derivedSuffix === 'string') {
        suffix = derivedSuffix;
    }

    const reqData: UploadFileRequest = {
        hash: hash,
        name: filename,
        size: blob.size,
        type: blob.type,
        suffix: suffix,
        filecontents: byteArray.buffer,
    };
    return reqData;
};

export const getDataFromLocalComputer = (file: File): Promise<UploadFileRequest | null> => {
    return getDataFromBlob(file, file.name);
};

// getDataFromLibrary
// TBD

export const bytesToKiloBytes = (bytes: number): number => {
    return bytes / 1000; 
};

export const getDocPath = (
    organizationId: number,
    funeralHomeId: number,
    caseId?: number,
    filename?: string,
): string => {
    return [organizationId, funeralHomeId, caseId ? caseId : 0, filename ? filename : ''].join('/');
};

export const downloadFromURL = (url: string, downloadName: string): void => {
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = downloadName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};
