import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import React, { ForwardedRef, forwardRef } from 'react';
import { LinkProps, NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { log } from '../../logger';
import {
    buildPathFromRoute,
    buildURLString,
    AppRoute,
    PathType,
    getIntercomTargetProp,
} from '../../services';

const useStyles = makeStyles({
    textDecorationNone: {
        textDecoration: 'none',
    },
}, { name: 'GLink' });

export interface GLinkProps extends Omit<LinkProps, 'to' | 'className'> {
    linkClass?: string;
    useNavLink?: boolean;
    to: AppRoute;
    disabledContainerClass?: string;
    intercomTargetProp?: string;
    intercomTargetValue?: string;
    disabledOnClick?: React.MouseEventHandler<HTMLElement>;
    disabled?: boolean;
}

const GLink = (props: GLinkProps, ref: ForwardedRef<HTMLAnchorElement>) => {
    const {
        linkClass,
        to,
        useNavLink,
        children,
        onClick,
        target,
        disabledContainerClass,
        intercomTargetProp,
        intercomTargetValue,
        disabledOnClick,
        disabled,
        ...linkProps
    } = props;

    const classes = useStyles();
    const path = buildPathFromRoute(to);
    switch (path.type) {
        case PathType.DISABLED:
            return (
                <span
                    ref={ref}
                    className={disabledContainerClass}
                    onClick={disabledOnClick}
                    {...getIntercomTargetProp(
                        (intercomTargetProp && intercomTargetValue)
                        ? `${intercomTargetProp}-${intercomTargetValue}` : ``)}
                >
                    {props.children}
                </span>
            );
        case PathType.ABSOLUTE:
            return (
                <a
                    {...linkProps}
                    href={disabled ? '' : buildURLString(path.urlParts)}
                    onClick={disabled ? undefined : onClick}
                    target={target}
                    ref={ref}
                    className={classNames(classes.textDecorationNone, linkClass)}
                    {...getIntercomTargetProp(
                        (intercomTargetProp && intercomTargetValue)
                        ? `${intercomTargetProp}-${intercomTargetValue}` : ``)}
                >
                    {children}
                </a>
            );
        case PathType.RELATIVE:
            const LinkComponent = useNavLink ? NavLink : Link;
            return (
                <LinkComponent
                    {...linkProps}
                    to={disabled ? '' : buildURLString(path.urlParts)}
                    ref={ref}
                    className={classNames(classes.textDecorationNone, linkClass)}
                    onClick={disabled ? undefined : onClick}
                    {...getIntercomTargetProp(
                        (intercomTargetProp && intercomTargetValue)
                        ? `${intercomTargetProp}-${intercomTargetValue}` : ``)}
                >
                    {children}
                </LinkComponent >
            );
        default:
            log.warn('Invalid PathType', { path });
            return null;
    }
};

export default forwardRef(GLink);
