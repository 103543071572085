import classNames from 'classnames';

import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';

import Clear from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { ProductPackageUX } from '../../../../shared/types';
import VerticalReactVirtualizedList from '../../../common/reactVirtualized/VerticalReactVirtualizedList';
import { getPackageDisplayPrice } from '../../../../shared/goods_and_services/pricing';
import { SlideTransition } from '../../../common/Transitions';
import DisabledPermissionTooltip from '../../../common/DisabledPermissionTooltip';
import { Permission } from '../../../../shared/types/permissions';
import ListItemButton from '@mui/material/ListItemButton';
import { GStyles } from '../../../../styles/GStyles';
import { useState } from 'react';
import useFullScreen from '../../../common/hooks/useFullScreen';
import { useStyles } from './AddItem.dialog';

interface Props {
    packageDetails: ProductPackageUX[];
    isDialogOpen: boolean;
    closeDialog: () => void;
    onSelectProduct: (packageId: number) => void;
    zIndex: number;
}

const AddPackageDialog = (props: Props) => {
    const {
        closeDialog,
        isDialogOpen,
        onSelectProduct,
        packageDetails,
        zIndex,
    } = props;

    const classes = useStyles();
    const fullScreen = useFullScreen();

    const [searchText, setSearchText] = useState('');
    const [listScrollElRef, setListScrollElRef] = useState<HTMLDivElement | null>(null);

    const applySearchFilter = (packages: ProductPackageUX[]) => {
        const search = searchText.toLowerCase();
        return packages.filter((_package) => {
            const name = _package.name.toLowerCase();
            const formattedPrice = getPackageDisplayPrice(_package, '$0,0.00');

            return (
                name.includes(search) ||
                search.includes(name) ||
                (formattedPrice && formattedPrice.includes(search))
            );
        });
    };

    const renderNoResultsFoundSection = () => {
        return (
            <Grid item xs={12} className={classes.noResultsFoundSection}>
                <Typography color="secondary" component="p" className={classes.sorryText}>
                    Sorry, no results found.
                </Typography>
                <Divider className={classes.noResultsDivider} />
            </Grid>
        );
    };

    const renderProduct = (product: ProductPackageUX) => {

        const price = getPackageDisplayPrice(product, '$0,0.00');

        return (
            <DisabledPermissionTooltip key={product.id} permission={Permission.ADD_ITEMS}>
                {(disabled) => (
                    <ListItemButton
                        disabled={disabled}
                        key={product.id}
                        onClick={(e) => handleSelectProduct(product)}
                        divider
                        className={classes.listItem}
                    >
                        <ListItemText
                            classes={{
                                primary: GStyles.fontWeight300,
                                secondary: GStyles.fontWeight300,
                            }}
                            primary={
                                <div className={classes.displayIcon}>
                                    {' '}
                                    <VisibilityOffIcon color="primary" />
                                    &nbsp;
                                    {product.name}
                                </div>
                            }
                            secondary={price && `Price: ${price}`}
                        />
                    </ListItemButton>
                )}
            </DisabledPermissionTooltip>
        );
    };

    const renderPackages = () => {
        const filteredProducts = applySearchFilter(packageDetails);
        if (filteredProducts.length === 0) {
            return renderNoResultsFoundSection();
        }

        return (
            <div ref={setListScrollElRef}>
                <List component="nav" className={classes.listContainer}>
                    <Divider sx={{ backgroundColor: 'transparent' }} />
                    <VerticalReactVirtualizedList
                        data={packageDetails}
                        defaultCellHeight={67}
                        scrollElement={listScrollElRef || undefined}
                        render={renderProduct}
                    />
                </List>
            </div>
        );
    };

    const handleSelectProduct = (product: ProductPackageUX) => {
        onSelectProduct(product.id);
        handleClose();
    };

    const handleClose = () => {
        setSearchText('');
        closeDialog();
    };

    return (
        <Dialog
            fullScreen={fullScreen}
            open={isDialogOpen}
            TransitionComponent={SlideTransition}
            transitionDuration={300}
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            className={classes.root}
            style={{ zIndex }}
            classes={{ paper: classes.dialogPaper }}
        >
            <DialogTitle
                id="alert-dialog-slide-title"
                className={classNames(classes.dialogHeader, GStyles.backgroundPrimary)}
            >
                <Clear className={classNames(classes.clearIcon)} onClick={handleClose} />
                <Typography component="p" className={classNames(classes.heading)} align="left">
                    <span>What would you like to add?</span>
                </Typography>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Grid container>
                    <Grid item xs={12} className={GStyles.textCenter}>
                        <form noValidate autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                            <TextField
                                autoComplete="off"
                                className={classes.searchField}
                                fullWidth
                                autoFocus
                                value={searchText}
                                id="input-with-icon-textfield"
                                placeholder="Start typing to filter list..."
                                name="searchText"
                                onChange={(evt) => setSearchText(evt.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon color="primary" />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </form>
                        {renderPackages()}
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default AddPackageDialog;
