import * as React from 'react';
import { CellMeasurer, CellMeasurerCache } from 'react-virtualized/dist/commonjs/CellMeasurer';
import { StyledProps, wrappedStyles } from './styles';
import { AutoSizer, createMasonryCellPositioner, Masonry, MasonryCellProps, Positioner } from 'react-virtualized';
import { GatherPhoto } from '../../../types';
import Zoom from '@mui/material/Zoom';
import MasonryPhoto from './MasonryPhoto';
import { WindowScroller } from 'react-virtualized/dist/commonjs/WindowScroller';
import { SharedProps } from './index';
import { isEqual } from 'lodash';
import { DEFAULT_HEIGHT, DEFAULT_WIDTH } from '../../../services';
import withGStyles from '../../../styles/WithGStyles';

type HeightAndWidth = {
    height: number;
    width: number;
};

const COLUMN_GUTTER = 15;

interface Props extends SharedProps {
    imageIsSelected: (photo: GatherPhoto) => boolean;
    activePhotoId: string | null;
    openPopper: (e: React.MouseEvent<HTMLElement>, photoId: string | null) => void;
    isPopperOpen: boolean;
    isUploadingPhoto: boolean;
    onPhotoClick: (chosenImage: GatherPhoto) => void;
    renderUploadNewPhoto: () => JSX.Element;
    renderDownloadPhotosButton: () => JSX.Element;
}

class VirtualMasonryPhotoList extends React.Component<Props & StyledProps> {
    cellMeasurerCache: CellMeasurerCache;
    cellPositioner: Positioner;
    columnWidth: number;
    numColumns: number;
    masonryRef: Masonry | null;
    windowScrollRef: WindowScroller | null;

    constructor(props: Props & StyledProps) {
        super(props);

        this.cellMeasurerCache = new CellMeasurerCache({
            defaultHeight: DEFAULT_HEIGHT,
            defaultWidth: DEFAULT_WIDTH,
            fixedWidth: true,
        });
    }

    componentDidMount() {
        setTimeout(() => this.windowScrollRef && this.windowScrollRef.updatePosition(), 400);
    }

    componentDidUpdate(prevProps: Readonly<Props & StyledProps>) {
        const {
            photoList: prevList,
            selectedPhotoIds: prevSelectedPhotoIds,
            isUploadingPhoto: prevIsUploadingPhoto
        } = prevProps;
        const { photoList, selectedPhotoIds, isUploadingPhoto } = this.props;

        // note: using isEquals on its own didn't seem to detect length changes correctly
        if ((prevList.length !== photoList.length || !isEqual(prevList, photoList)) && this.masonryRef) {
            this.cellMeasurerCache.clearAll();
            this.cellPositioner.reset({
                columnCount: this.numColumns,
                columnWidth: this.columnWidth,
                spacer: COLUMN_GUTTER
            });
            this.masonryRef.clearCellPositions();
        }

        if (this.masonryRef && (
            !isEqual(prevSelectedPhotoIds, selectedPhotoIds)
            || prevIsUploadingPhoto !== isUploadingPhoto
        )) {
            this.masonryRef.forceUpdate(); // so we show selection state change
        }
    }

    handlePhotoClick = (chosenImage: GatherPhoto) => {
        this.props.onPhotoClick(chosenImage);
    };

    photoKeyMapper = (index: number) => {
        const { photoList, hideUploadButton, hideDownloadButton } = this.props;
        const totalLength = photoList.length + (hideUploadButton ? 0 : 1) + (hideDownloadButton ? 0 : 1);

        if (index >= totalLength) {
            return 'error';
        }
        if (!hideUploadButton && index === 0) {
            return 'upload-button';
        }
        if (!hideDownloadButton && index === totalLength - 1) {
            return 'download-button';
        }
        const computedIndex = hideUploadButton ? index : index - 1;
        return photoList[computedIndex].gatherId;
    };

    calculateColumnCountAndWidth = (width: number) => {
        const { isLargePhotoView } = this.props;

        const breakPoints = isLargePhotoView ?
            { 0: 1, 230: 1, 500: 2, 800: 3 } : { 0: 1, 230: 2, 500: 3, 800: 4 };
        const numColumns: number = Object
            .keys(breakPoints)
            .reduce((prevValue, currentValue) => (width > +currentValue) ? breakPoints[currentValue] : prevValue, 0);

        this.numColumns = numColumns;
        this.columnWidth = Math.floor((width - ((numColumns - 1) * 15) - 40) / numColumns);
    };

    resizeColumns = ({ width }: HeightAndWidth) => {
        this.calculateColumnCountAndWidth(width);
        this.cellMeasurerCache.clearAll();
        if (this.cellPositioner) {
            this.cellPositioner.reset({
                columnCount: this.numColumns,
                columnWidth: this.columnWidth,
                spacer: COLUMN_GUTTER
            });
        }
        if (this.masonryRef) {
            this.masonryRef.clearCellPositions();
        }
    };

    computeColumns = ({ width }: HeightAndWidth) => {
        const { scrollElement } = this.props;
        this.calculateColumnCountAndWidth(width);
        if (scrollElement) {
            if (!this.cellPositioner) {
                this.cellPositioner = createMasonryCellPositioner({
                    cellMeasurerCache: this.cellMeasurerCache,
                    columnCount: this.numColumns,
                    columnWidth: this.columnWidth,
                    spacer: COLUMN_GUTTER,
                });
            }
        }
    };

    cellRenderer = ({ index, key, parent, style }: MasonryCellProps) => {
        const {
            activePhotoId,
            photoList,
            imageIsSelected,
            photoOptions,
            allowMultiSelection,
            isPhotoDeselecting,
            isPhotoSelecting,
            resetPhotoSelectDeselect,
            selectedPhotoId,
            enablePhotoSwipe,
            hideEditButton,
            openPopper,
            isPopperOpen,
            hideUploadButton,
            hideDownloadButton,
            renderUploadNewPhoto,
            renderDownloadPhotosButton,
            editPhotoButtonClass,
        } = this.props;

        let content;
        const totalLength = photoList.length + (hideUploadButton ? 0 : 1) + (hideDownloadButton ? 0 : 1);
        const computedIndex = hideUploadButton ? index : index - 1;

        if (index >= totalLength) {
            return;
        }

        if (index === 0 && !hideUploadButton) {
            content = renderUploadNewPhoto();
        } else if (index === totalLength - 1 && !hideDownloadButton) {
            content = renderDownloadPhotosButton();
        } else {
            const photo = photoList[computedIndex]; // the add photo is the first element now
            content = (
                <Zoom in timeout={1200}>
                    <MasonryPhoto
                        photo={photo}
                        photos={photoList}
                        enablePhotoSwipe={enablePhotoSwipe}
                        photoOptions={photoOptions}
                        onPhotoClick={() => this.handlePhotoClick(photo)}
                        multiSelect={allowMultiSelection}
                        isSelected={imageIsSelected(photo)}
                        selectedPhotoId={selectedPhotoId}
                        resetPhotoSelectDeselect={resetPhotoSelectDeselect}
                        isPhotoDeselecting={isPhotoDeselecting}
                        isPhotoSelecting={isPhotoSelecting}
                        activePhotoId={activePhotoId}
                        openPopper={(e, photoId) => openPopper(e, photoId)}
                        isOpenPhotoPopper={isPopperOpen}
                        hideEditButton={hideEditButton || false}
                        columnWidth={this.columnWidth}
                        editPhotoButtonClass={editPhotoButtonClass}
                    />
                </Zoom>
            );
        }

        return (
            <CellMeasurer
                cache={this.cellMeasurerCache}
                index={index}
                key={key}
                parent={parent}
            >
                <div style={{ ...style, width: this.columnWidth }}>{content}</div>
            </CellMeasurer>
        );
    };

    render() {
        const {
            classes,
            photoList,
            scrollElement,
            hideUploadButton,
            hideDownloadButton,
        } = this.props;

        const totalLength = photoList.length + (hideUploadButton ? 0 : 1) + (hideDownloadButton ? 0 : 1);

        return (
            <WindowScroller
                scrollElement={scrollElement}
                onResize={this.resizeColumns}
                ref={ref => this.windowScrollRef = ref}
            >
                {({ height, isScrolling, scrollTop }) => (
                    <AutoSizer
                        disableHeight
                        onResize={this.resizeColumns}
                        scrollTop={scrollTop}
                        height={height}
                    >
                        {({ width }) => {
                            if (!width || !height) {
                                return <></>;
                            }
                            this.computeColumns({ width, height });

                            return (
                                <Masonry
                                    className={classes.containerPad}
                                    autoHeight
                                    cellCount={totalLength}
                                    cellMeasurerCache={this.cellMeasurerCache}
                                    cellPositioner={this.cellPositioner}
                                    cellRenderer={this.cellRenderer}
                                    scrollTop={scrollTop}
                                    isScrolling={isScrolling}
                                    height={height}
                                    width={width}
                                    keyMapper={this.photoKeyMapper}
                                    ref={node => this.masonryRef = node}
                                />
                            );
                        }}
                    </AutoSizer>
                )}
            </WindowScroller>
        );
    }
}

export default withGStyles(wrappedStyles<Props>())(VirtualMasonryPhotoList);
