import { CropperRef, Cropper, CircleStencil, RectangleStencil } from 'react-mobile-cropper';
import 'react-mobile-cropper/dist/style.css';
import {
    DEFAULT_HEIGHT,
    DEFAULT_WIDTH,
    getImageSizeFromDataURI,
    getPhotoUrlWithoutTransformations,
    rotationTypeToString,
    ImageSizeType,
    LANDSCAPE_ASPECT_RATIO,
    PhotoOrientationType,
    PORTRAIT_ASPECT_RATIO,
    SQUARE_ASPECT_RATIO,
    stringToRotationType,
} from '../../services';
import { GatherPhoto } from '../../types';
import makeGStyles from '../../styles/makeGStyles';
import {
    lighten,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import {
    CloudinaryTransformationsType,
    CoverPhotoEnum,
    CropperStencilEnum,
} from '../../shared/types';
import classNames from 'classnames';

const useStyles = makeGStyles((theme) => ({
    clearIcon: {
        position: 'absolute',
        top: 12,
        right: 10,
        fontSize: 28,
        '&:hover': {
            cursor: 'pointer',
        },
        '@media (min-width: 400px)': {
            fontSize: 34,
        },
    },
    dialogFooter: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        zIndex: 1,
        padding: 14,
        '@media (min-width: 350px)': {
            minWidth: 300,
        },
    },
    revertButton: {
        width: 'fit-content',
        background: theme.palette.primary.main,
        fontSize: 16,
        '&:hover': {
            background: lighten(theme.palette.primary.main, 0.2),
        },
    },
    saveButton: {
        width: 'fit-content',
        background: theme.palette.primary.main,
        fontSize: 16,
        '&:hover': {
            background: lighten(theme.palette.primary.main, 0.2),
        },
    },
    flexContainer: {
        justifyContent: 'space-around',
        maxWidth: '100%',
    },
    // hide the rotation slider for now...
    hideRotationSlider: {
        '& .rmc-navigation': {
            justifyContent: 'space-between',
        },
        '& .rmc-navigation__rotator': {
            display: 'none',
        },
    },
}));

// Document the props for the component
interface PhotoCropperProps {
    imageURI: string;
    initialTransformations?: CloudinaryTransformationsType;
    stencilType?: CropperStencilEnum;
    orientation?: PhotoOrientationType;
    activePhoto?: GatherPhoto;
    coverPhotoType?: CoverPhotoEnum;
    cropChanged: (transformations: CloudinaryTransformationsType, cropperRef: CropperRef | null) => void;
    revision: number;
    isRevertClicked?: boolean;
}

const BasePhotoCropper = (props: PhotoCropperProps) => {
    const classes = useStyles();
    const {
        imageURI,
        stencilType,
        orientation,
        activePhoto,
        cropChanged,
        revision,
        initialTransformations,
        isRevertClicked,
    } = props;
    const [image, setImage] = useState<string>(imageURI);
    const [rawImageSize, setRawImageSize] = useState<ImageSizeType>({
        width: DEFAULT_WIDTH,
        height: DEFAULT_HEIGHT
    });
    const [lastCrop, setLastCrop] = useState<CloudinaryTransformationsType | null>
        (initialTransformations ?? null);

    const stencil = stencilType === CropperStencilEnum.circle ? CircleStencil : RectangleStencil;

    const cropperRef = useRef<CropperRef>(null);

    // Need a better way to check this, but right now if a photo_view has transformations, it will have an angle
    const getDefaultTransformations = () => {
        let defaultCoordinates;
        let defaultTransforms;
        defaultCoordinates = {
            left: 0,
            top: 0,
            width: rawImageSize.width,
            height: rawImageSize.height,
        };
        defaultTransforms = {
            rotate: 0,
            flip: {
                horizontal: false,
                vertical: false,
            },
        };
        if (!isRevertClicked) {
            defaultCoordinates = initialTransformations?.angle ? {
                left: initialTransformations?.x || 0,
                top: initialTransformations?.y || 0,
                width: initialTransformations?.width || rawImageSize.width,
                height: initialTransformations?.height || rawImageSize.height,
            } : undefined;
            const rotations = stringToRotationType(initialTransformations?.angle || '0');
            defaultTransforms = {
                rotate: rotations.angle,
                flip: {
                    horizontal: rotations.horizontal,
                    vertical: rotations.vertical,
                },
            };
        } else {
            defaultCoordinates = {
                left: 0,
                top: 0,
                width: rawImageSize.width,
                height: rawImageSize.height,
            };
            defaultTransforms = {
                rotate: 0,
                flip: {
                    horizontal: false,
                    vertical: false,
                },
            };
        }
        return { defaultCoordinates, defaultTransforms };
    };

    useEffect(() => {
        const loadPhoto = async () => {
            if (imageURI !== '') {
                setImage(imageURI);
                const imgSize = await getImageSizeFromDataURI(imageURI);
                setRawImageSize(imgSize);
            } else if (activePhoto) {
                const imageUrl =
                    getPhotoUrlWithoutTransformations(activePhoto.photo ? activePhoto.photo.public_id : '');
                setImage(imageUrl);
                setRawImageSize({
                    width: activePhoto.photo?.width || DEFAULT_WIDTH,
                    height: activePhoto.photo?.height || DEFAULT_HEIGHT
                });
            }
        };
        loadPhoto();
    }, [activePhoto, imageURI]);

    useEffect(() => {
        if (revision > 0 && cropperRef.current) {
            cropperRef.current.reset();
        }
    }, [revision]);

    const getAspectRatio = () => {
        if (orientation === 'square') {
            return SQUARE_ASPECT_RATIO;
        } else if (orientation === 'landscape') {
            return LANDSCAPE_ASPECT_RATIO;
        } else if (orientation === 'portrait') {
            return PORTRAIT_ASPECT_RATIO;
        } else {
            return undefined;
        }
    };

    const handleCropEnd = (cropper: CropperRef) => {
        const cropState = cropper.getState();
        if (!cropState) {
            return;
        }

        const cropX = cropState.coordinates ? Math.round(cropState.coordinates.left) : 0;
        const cropY = cropState.coordinates ? Math.round(cropState.coordinates.top) : 0;
        const cropWidth = cropState.coordinates ? Math.round(cropState.coordinates.width) : rawImageSize.width;
        const cropHeight = cropState.coordinates ? Math.round(cropState.coordinates.height) : rawImageSize.height;
        const rotation = cropState.transforms.rotate % 360;
        const finalRotation = rotationTypeToString({
            angle: rotation,
            vertical: cropState.transforms.flip.vertical,
            horizontal: cropState.transforms.flip.horizontal
        });

        const finalCrop: CloudinaryTransformationsType = {
            crop: 'crop',
            width: cropWidth,
            height: cropHeight,
            x: cropX,
            y: cropY,
            angle: finalRotation,
        };
        if (lastCrop === null || JSON.stringify(lastCrop) !== JSON.stringify(finalCrop)) {
            cropChanged(finalCrop, cropper);
            setLastCrop(finalCrop);
        }
    };

    const setInitialCrop = async (cropper: CropperRef) => {
        cropper.refresh();
        handleCropEnd(cropper);
    };

    return (
        <Cropper
            className={classNames('cropper', classes.hideRotationSlider)}
            ref={cropperRef}
            src={image}
            onInteractionEnd={handleCropEnd}
            onTransformImageEnd={handleCropEnd}
            onReady={setInitialCrop}
            defaultCoordinates={getDefaultTransformations().defaultCoordinates}
            defaultTransforms={getDefaultTransformations().defaultTransforms}
            stencilComponent={stencil}
            stencilProps={{
                grid: true,
                aspectRatio: getAspectRatio(),
            }}
            resizeImage
        />
    );
};

export default BasePhotoCropper;
