import {
    DeathCertificateRaceType,
    UserProfile,
    DeathCertificateConfigUX,
    YesNoUnknownEnum,
    RaceEnum,
    HispanicOriginEnum,
    AncestryEnum
} from '../../../shared/types';
import { ConfigurableRaceFieldKey, isFieldOptional } from './config';

const _hasRaceSelected = <T extends string>(raceList: T[] | undefined, key: T): boolean => {
    if (!raceList) {
        return false;
    }
    return raceList.some(r => r === key);
};

export const raceValidators = {
    race: function (
        { race }: DeathCertificateRaceType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean
    ): boolean {
        const isValid = race && race.length;
        if (isValid) {
            return true;
        }

        if (isFieldOptional(ConfigurableRaceFieldKey.personRace, config, user)) {
            return !touchCheck;
        }

        return false;
    },
    hispanicOrigin: function (
        { hispanicOrigin }: DeathCertificateRaceType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean
    ): boolean {
        const isValid = hispanicOrigin && hispanicOrigin.length;
        if (isValid) {
            return true;
        }

        if (isFieldOptional(ConfigurableRaceFieldKey.ofHispanicOrigins, config, user)) {
            return !touchCheck;
        }

        return false;
    },
    hispanicOriginSpecifyOther: function (
        { hispanicOrigin_specify_other: otherHispanicOrigin, hispanicOrigin }: DeathCertificateRaceType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean
    ): boolean {
        const isSelected = _hasRaceSelected(hispanicOrigin, HispanicOriginEnum['Yes, Other Spanish/Hispanic/Latino']);
        const isValid = otherHispanicOrigin && otherHispanicOrigin.length > 0;

        if (isValid || !isSelected || isFieldOptional(ConfigurableRaceFieldKey.hispanicOriginOther, config, user)) {
            return !touchCheck;
        }

        return false;
    },
    enrolledOrPrinciple: function (
        { race, race_specify_indian_tribe: specifyIndianTribe }: DeathCertificateRaceType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean
    ): boolean {
        const isSelected = _hasRaceSelected(race, RaceEnum['American Indian or Alaska Native']);
        const isValid = specifyIndianTribe && specifyIndianTribe.length > 0;

        if (isValid || !isSelected || isFieldOptional(ConfigurableRaceFieldKey.indianTribe, config, user)) {
            return !touchCheck;
        }

        return false;
    },
    otherAsian: function (
        { race_specify_asian: specifyOtherAsian, race }: DeathCertificateRaceType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean
    ): boolean {
        const isSelected = _hasRaceSelected(race, RaceEnum['Asian (Specify)']);
        const isValid = specifyOtherAsian && specifyOtherAsian.length > 0;

        if (isValid || !isSelected || isFieldOptional(ConfigurableRaceFieldKey.asianOther, config, user)) {
            return !touchCheck;
        }

        return false;
    },
    otherPacificIslander: function (
        { race_specify_islander: otherPacificIslander, race }: DeathCertificateRaceType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean
    ) {
        const isSelected = _hasRaceSelected(race, RaceEnum['Other Pacific Islander']);
        const isValid = otherPacificIslander && otherPacificIslander.length > 0;

        if (isValid || !isSelected || isFieldOptional(ConfigurableRaceFieldKey.otherPacificIslander, config, user)) {
            return !touchCheck;
        }

        return false;
    },
    otherRace: function (
        { race_specify_other: otherRace, race }: DeathCertificateRaceType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean
    ) {
        const isSelected = _hasRaceSelected(race, RaceEnum.Other);
        const isValid = otherRace && otherRace.length > 0;

        if (isValid || !isSelected || isFieldOptional(ConfigurableRaceFieldKey.otherRace, config, user)) {
            return !touchCheck;
        }

        return false;
    },
    isUsCitizen: function (
        { isUsCitizen }: DeathCertificateRaceType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean
    ) {
        const isValid = isUsCitizen && YesNoUnknownEnum[isUsCitizen];
        if (isValid) {
            return true;
        }

        if (isFieldOptional(ConfigurableRaceFieldKey.usCitizen, config, user)) {
            return !touchCheck;
        }

        return false;
    },

    ancestry: function (
        { ancestry }: DeathCertificateRaceType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean
    ): boolean {
        const isValid = ancestry && ancestry.length;
        if (isValid) {
            return true;
        }

        if (isFieldOptional(ConfigurableRaceFieldKey.ancestry, config, user)) {
            return !touchCheck;
        }

        return false;
    },

    otherAncestry: function (
        { ancestry, race_specify_other_ancestry: otherAncestry }: DeathCertificateRaceType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean
    ): boolean { 
        const isSelected = _hasRaceSelected(ancestry, AncestryEnum['Other Ancestry']);
        const isValid = otherAncestry && otherAncestry.length;

        if (isValid || !isSelected || isFieldOptional(ConfigurableRaceFieldKey.otherAncestry, config, user)) {
            return !touchCheck;
        }

        return false;
    },

    americanIndianTribe: function (
        { ancestry, americanIndian_specifiy_tribe: americanIndianTribe }: DeathCertificateRaceType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean
    ): boolean {
        const isSelected = _hasRaceSelected(ancestry, AncestryEnum['American Indian (Specify Tribe)']);
        const isValid = americanIndianTribe && americanIndianTribe.length;

        if (isValid || !isSelected || isFieldOptional(ConfigurableRaceFieldKey.americanIndianTribe, config, user)) {
            return !touchCheck;
        }

        return false;
    },

    hispanicRaceSpecify: function (
        { race_specify_other_hispanic: otherHispanicRace, race }: DeathCertificateRaceType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean
    ): boolean {
        const isSelected = _hasRaceSelected(race, RaceEnum['Hispanic/Latino (Specify)']);
        const isValid = otherHispanicRace && otherHispanicRace.length;

        if (isValid || !isSelected || isFieldOptional(ConfigurableRaceFieldKey.otherHispanicRace, config, user)) {
            return !touchCheck;
        }

        return false;
    },

    otherOtherAsian: function (
        { race_specify_other_asian: otherOtherAsian, race }: DeathCertificateRaceType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean
    ): boolean {
        const isSelected = _hasRaceSelected(race, RaceEnum['Other Asian']);
        const isValid = otherOtherAsian && otherOtherAsian.length;

        if (isValid || !isSelected || isFieldOptional(ConfigurableRaceFieldKey.otherOtherAsian, config, user)) {
            return !touchCheck;
        }

        return false;
    }
};

export const raceMasterValidators = {
    valid: function (
        raceForm: DeathCertificateRaceType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
    ): boolean {
        return raceValidators.enrolledOrPrinciple(raceForm, config, user)
            && raceValidators.race(raceForm, config, user)
            && raceValidators.hispanicOrigin(raceForm, config, user)
            && raceValidators.isUsCitizen(raceForm, config, user)
            && raceValidators.otherAsian(raceForm, config, user)
            && raceValidators.otherPacificIslander(raceForm, config, user)
            && raceValidators.otherRace(raceForm, config, user)
            && raceValidators.hispanicOriginSpecifyOther(raceForm, config, user)
            && raceValidators.ancestry(raceForm, config, user)
            && raceValidators.otherAncestry(raceForm, config, user)
            && raceValidators.americanIndianTribe(raceForm, config, user)
            && raceValidators.hispanicRaceSpecify(raceForm, config, user);

    },
    touched: function (
        raceForm: DeathCertificateRaceType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
    ): boolean {
        return raceValidators.hispanicOrigin(raceForm, config, user, true)
            || raceValidators.race(raceForm, config, user, true)
            || raceValidators.isUsCitizen(raceForm, config, user, true)
            || raceValidators.enrolledOrPrinciple(raceForm, config, user, true)
            || raceValidators.otherAsian(raceForm, config, user, true)
            || raceValidators.otherPacificIslander(raceForm, config, user, true)
            || raceValidators.otherRace(raceForm, config, user, true)
            || raceValidators.hispanicOriginSpecifyOther(raceForm, config, user, true)
            || raceValidators.ancestry(raceForm, config, user, true)
            || raceValidators.otherAncestry(raceForm, config, user, true)
            || raceValidators.americanIndianTribe(raceForm, config, user, true)
            || raceValidators.hispanicRaceSpecify(raceForm, config, user, true);
    },
};
