import * as React from 'react';
import classNames from 'classnames';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import CloudUploadIcon from '@mui/icons-material/CloudDownload';
import { PopoverReference } from '@mui/material/Popover';

import { StoreState } from '../../../types';
import { getDataURIFromFile } from '../../../services';
import PhotoCropper from '../../profileImage/PhotoCropper';
import {
    PhotoTransformationsType,
    DocPacketUX,
    GatherCaseUX,
    UploadFileRequest,
    DocUX,
    DocDownloadResponse,
    DocUXDetail,
    UserRoles
} from '../../../shared/types';
import { getDataFromLocalComputer, downloadFromURL, getBlobFromData
} from '../../../services/doc.service';
import { MenuItemsKey } from '../UploadFileMenuPopper';
import {
    uploadDocPacketSignedDoc,
    deleteDocPacketSignedDoc,
    markDocPacketSigned,
    renameDocPacketSignedDoc,
} from '../../../actions/DocPacket.action';
import {
    convertUXtoUXDetail,
    convertDocUploadToUXDetail,
} from '../../../actions/Doc.action';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback } from '@mui/styles';
import { AppDispatch } from '../../../store';
import withState from '../../common/utilHOC/WithState';
import withGStyles, { WithGStyles } from '../../../styles/WithGStyles';
import { DocType } from '../DocPreview.dialog';
import DocItemList from './DocItemList';
import { log } from '../../../logger';

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {
        alignItems: 'initial',
        margin: '0px 8px',
        overflow: 'hidden'
    },
    buttonSection: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
        '& $infoText': {
            fontWeight: 200,
            maxWidth: 360,
            width: 'calc(100% - 32px)',
            textAlign: 'center',
            marginBottom: 10
        },
        '& $button': {
            maxWidth: 420,
        }
    },
    uploadButton: {
        marginTop: '16px !important',
    },
    items: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        float: 'left',
        margin: '12px 0px',
        width: 'calc(100% - 8px)',
        maxWidth: 420,
        '@media (min-width: 888px)': { // 420 * 2 + padding:48
            justifyContent: 'flex-start',
        },
        '@media (min-width: 960px)': {
            justifyContent: 'center',
        },
        '@media (min-width: 1208px)': {
            justifyContent: 'flex-start',
        },
        '& $item': {
            width: '100%',
            maxWidth: 420,
        }
    },
    item: {},
    button: {},
    infoText: {}
});

// props from redux store
const mapStateToProps = ({
    userSession,
}: StoreState) => {
    return {
        userSession,
    };
};

type Props = ReturnType<typeof mapStateToProps> & {
    docPacket: DocPacketUX;
    activeCase: GatherCaseUX;
    dispatch: AppDispatch;
    onDownloadSignedDoc: (doc: DocUX) => Promise<DocDownloadResponse | null>;
    zIndex: number;
    toggleDocMenuOpen?: (isOpen: boolean) => void;
    menuAnchorReference?: PopoverReference;
};

interface State {
    isPhotoCropperOpen: boolean;
    imageUri: string;
    imageName: string;
    signedDocs: DocUXDetail[];
}

type StyledProps = Props & WithGStyles<'root' | 'buttonSection' | 'uploadButton' | 'items' | 'item'
    | 'button' | 'infoText'>;

class PrintSignSignatures extends React.Component<StyledProps, State> {
    state: State = {
        isPhotoCropperOpen: false,
        imageUri: '',
        imageName: '',
        signedDocs: this.props.docPacket.signedDocs.map(convertUXtoUXDetail),
    };

    componentWillUnmount() {
        const { toggleDocMenuOpen } = this.props;

        if (toggleDocMenuOpen) {
            toggleDocMenuOpen(false);
        }
    }

    protected fileUploadInput: HTMLInputElement | null;

    handleFileUploadEvent = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files || event.target.files.length === 0) {
            return;
        }

        const file = event.target.files[0];

        if (file.type === 'application/pdf') {
            const result = await getDataFromLocalComputer(file);
            if (!result) {
                return;
            }
            this.handleDocUpload(result);
        } else {
            const dataURI = await getDataURIFromFile(file);
            this.setState({
                imageUri: dataURI,
                imageName: file.name,
            });
            this.openPhotoCropper();
        }
    };

    openPhotoCropper = () => {
        const { toggleDocMenuOpen } = this.props;

        this.setState(
            { isPhotoCropperOpen: true },
            () => toggleDocMenuOpen && toggleDocMenuOpen(true)
        );
    };

    closePhotoCropper = () => {
        const { toggleDocMenuOpen } = this.props;

        this.setState(
            { isPhotoCropperOpen: false },
            () => toggleDocMenuOpen && toggleDocMenuOpen(false)
        );
    };

    handleCroppedPhoto = async (transformations: PhotoTransformationsType, uri: string) => {
        const { imageName } = this.state;

        if (!uri) {
            return;
        }
        const result = getBlobFromData(uri, imageName);
        if (result) {
            this.handleDocUpload(result);
        }
        this.closePhotoCropper();

    };

    addDoc = () => this.fileUploadInput && this.fileUploadInput.click();

    handleDocUpload = async (uploadReq: UploadFileRequest) => {
        const { dispatch, docPacket, activeCase, userSession } = this.props;
        const { userData } = userSession;

        if (!userData) {
            return;
        }

        // set doc uploading
        const placeholder = convertDocUploadToUXDetail(uploadReq);
        this.setState((prevState) => ({
            signedDocs: [...prevState.signedDocs, placeholder],
        }));

        const updatedPacket = await dispatch(uploadDocPacketSignedDoc(docPacket, uploadReq, activeCase, userData));
        const createdDoc = updatedPacket &&
            updatedPacket.signedDocs.find((signedDoc) => signedDoc.hash === uploadReq.hash) || null;

        // set doc uploaded
        this.setState((prevState) => ({
            signedDocs: prevState.signedDocs.map((signedDoc): DocUXDetail => {
                if (signedDoc.uid === placeholder.uid) {
                    return {
                        ...signedDoc,
                        doc: createdDoc,
                        status: 'uploaded'
                    };
                } else {
                    return signedDoc;
                }
            }),
        }));
    };

    handleDeleteDoc = async (docId: number) => {
        const { dispatch, docPacket, activeCase } = this.props;

        const signedDoc = docPacket.signedDocs.find(doc => doc.id === docId);
        if (!signedDoc) {
            log.warn(`Unable to find a doc with DocId: ${docId}`);
            return;
        }

        await dispatch(deleteDocPacketSignedDoc(docPacket, signedDoc, activeCase));
    };

    handleMarkAsSigned = () => {
        const { dispatch, docPacket, activeCase } = this.props;

        dispatch(markDocPacketSigned(docPacket, activeCase));
    };

    handleDocRename = (docId: number, newLabel: string) => {
        const { dispatch, activeCase, docPacket } = this.props;

        const activeSignedDoc = docPacket.signedDocs.find((signedDoc) => signedDoc.id === docId);
        if (!activeSignedDoc) {
            return;
        }

        // optimistically rename the document
        this.setState((prevState) => ({
            signedDocs: prevState.signedDocs.map((signedDoc) => {
                if (signedDoc.doc && signedDoc.doc.id === activeSignedDoc.id) {
                    return {
                        ...signedDoc,
                        label: newLabel,
                    };
                } else {
                    return signedDoc;
                }
            }),
        }));
        dispatch(renameDocPacketSignedDoc(docPacket, activeSignedDoc, newLabel, activeCase));
    };

    handleDocDownload = async (docId: number) => {
        const { onDownloadSignedDoc, docPacket } = this.props;

        const signedDoc = docPacket.signedDocs.find(doc => doc.id === docId);
        if (!signedDoc || !signedDoc) {
            log.warn(`Unable to find a doc with DocId: ${docId}`);
            return;
        }

        const downloadDocResult = await onDownloadSignedDoc(signedDoc);
        if (!downloadDocResult) {
            return;
        }
        downloadFromURL(downloadDocResult.presignedurl, downloadDocResult.downloadfilename);
    };

    handleUploadFileMenuItemClick = (key: MenuItemsKey) => {
        if (key === 'NEW' && this.fileUploadInput) {
            this.fileUploadInput.click();
        }
    };

    renderContent = () => {
        const { classes, userSession, activeCase, toggleDocMenuOpen, zIndex, menuAnchorReference } = this.props;
        const { signedDocs } = this.state;
        const isGOMUser = UserRoles.isGOMUser(userSession.userData);

        return (
            <Grid item xs={12} className={classes.items}>
                <DocItemList
                    docs={signedDocs}
                    zIndex={zIndex + 1}
                    activeFuneralHomeId={activeCase.funeral_home_id}
                    rootClass={classes.item}
                    isGOMUser={isGOMUser}
                    isCompactView
                    menuAnchorReference={menuAnchorReference}
                    docsType={DocType.Case}
                    setMenuOpen={toggleDocMenuOpen}
                    renameDoc={this.handleDocRename}
                    deleteDoc={this.handleDeleteDoc}
                    downloadDoc={this.handleDocDownload}
                />
            </Grid>
        );
    };

    render() {

        const { classes, docPacket, zIndex } = this.props;
        const { isPhotoCropperOpen, imageUri } = this.state;

        const isPrinted = docPacket.status === 'printed';
        const isSigned = docPacket.status === 'signed';

        const notSignedText = isPrinted
            ? 'Once you mark this packet as signed, you can upload a signed copy of the packet.'
            : 'Once your documents are printed, you will be able to mark them as signed';

        return (
            <Grid
                container
                justifyContent="center"
                alignItems="center"
                className={classes.root}
            >
                {!isSigned &&
                    <div className={classes.buttonSection}>
                        <Typography color="primary" className={classes.infoText}>
                            {notSignedText}
                        </Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            aria-label="add-doc"
                            onClick={this.handleMarkAsSigned}
                            disabled={!isPrinted}
                        >
                            <BeenhereIcon />
                            &nbsp;Mark Documents as Signed
                        </Button>
                    </div>
                }

                {isSigned &&
                    <div className={classNames(classes.buttonSection, classes.uploadButton)}>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            aria-label="add-doc"
                            onClick={(e) => this.fileUploadInput && this.fileUploadInput.click()}
                        >
                            <CloudUploadIcon />
                            &nbsp;Upload Signed Document
                        </Button>
                        {this.renderContent()}
                    </div>
                }

                <input
                    type="file"
                    accept="image/gif, image/jpeg, image/png, application/pdf"
                    multiple={false}
                    className={classes.displayNone}
                    ref={ele => this.fileUploadInput = ele}
                    onChange={this.handleFileUploadEvent}
                    onClick={e => {
                        const element = e.target as HTMLInputElement;
                        // clear this value to so that same photo can be chosen each time
                        element.value = '';
                    }}
                />

                <PhotoCropper
                    imageURI={imageUri}
                    onSaveImage={this.handleCroppedPhoto}
                    isDialogOpen={isPhotoCropperOpen}
                    closeDialog={this.closePhotoCropper}
                    zIndex={zIndex + 1}
                />
            </Grid>
        );
    }
}

export default withState(mapStateToProps)(withGStyles(styles)(PrintSignSignatures));
