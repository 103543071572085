import { Component } from 'react';
import {
    GatherCaseUX,
    ProductContractUX,
    DocPacketSignerUX,
    DocPacketContractSignerRecord,
} from '../../../../shared/types';
import { PayerDetails, PrintModeType } from '..';
import Overview from '.';

interface Props {
    printElementId: string;
    activeContract: ProductContractUX | null;
    printModeType: PrintModeType;
    isCondensedView: boolean;
    activeCase: GatherCaseUX;
    docPacketSigners?: DocPacketSignerUX[];
    teamContractSigners?: DocPacketContractSignerRecord[];
    helperContractSigners?: DocPacketContractSignerRecord[];
    isItemizedStatement: boolean;
    isDownloadingInvoice: boolean;
    payerDetails?: PayerDetails;
}

export class ContractPrintView extends Component<Props> {
    render() {
        const {
            printElementId,
            printModeType,
            activeContract,
            isCondensedView,
            activeCase,
            docPacketSigners,
            teamContractSigners,
            helperContractSigners,
            isItemizedStatement,
            isDownloadingInvoice,
            payerDetails,
        } = this.props;

        return (
            <Overview
                printElementId={printElementId}
                activeContract={activeContract}
                isHidden
                canUserEditContract={false}
                isPrintMode
                printModeType={printModeType}
                isCondensedView={isCondensedView}
                isContractFrozen={false}
                activeCase={activeCase}
                docPacketSigners={docPacketSigners}
                teamContractSigners={teamContractSigners}
                helperContractSigners={helperContractSigners}
                onAddAdditionalPackage={() => {
                    return;
                }}
                onAddAdditionalItem={() => {
                    return;
                }}
                onProductItemClick={() => {
                    return;
                }}
                onUpdateItemPriceAdjustment={() => {
                    return;
                }}
                onUpdateItemPrice={() => {
                    return;
                }}
                onUpdateItemNote={() => {
                    return;
                }}
                onRemoveContractItem={() => {
                    return;
                }}
                openFreezeContractDialog={() => {
                    return;
                }}
                printContract={() => {
                    return;
                }}
                toggleCondensedView={() => {
                    return;
                }}
                onAddContractDiscountItem={() => {
                    return;
                }}
                onUpdateContractDiscountItem={() => {
                    return;
                }}
                zIndex={1320}
                isItemizedStatement={isItemizedStatement}
                isDownloadingInvoice={isDownloadingInvoice}
                payerDetails={payerDetails}
            />
        );
    }
}
