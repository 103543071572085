import { Dialog, Grid, lighten, Theme } from "@mui/material";
import { PhotoOrientationType } from "../../services";
import {
    CloudinaryTransformationsType,
    CoverPhotoEnum,
    CropperStencilEnum,
    PhotoTransformationsType,
    PhotoTypeEnum,
} from "../../shared/types";
import { SlideTransition } from "../common/Transitions";
import makeGStyles from "../../styles/makeGStyles";
import { useState } from "react";
import GButton from "../common/GButton";
import BasePhotoCropper from "./BasePhotoCropper";
import { GatherPhoto } from "../../types";
import { CropperRef } from "react-mobile-cropper";

const useStyles = makeGStyles((theme: Theme) => ({
    root: {
        maxWidth: '100%',
    },
    button: {
        margin: theme.spacing(),
    },
    cropperDialog: {
        overflowX: 'hidden',
        margin: 0,
        minwidth: 600,
        minHeight: 320,
        maxHeight: '90vh',
    },
    dialogFooter: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        zIndex: 1,
        padding: 14,
        '@media (min-width: 350px)': {
            minWidth: 300,
        },
    },
    revertButton: {
        width: 'fit-content',
        background: theme.palette.primary.main,
        fontSize: 16,
        '&:hover': {
            background: lighten(theme.palette.primary.main, 0.2),
        },
    },
    saveButton: {
        width: 'fit-content',
        background: theme.palette.primary.main,
        fontSize: 16,
        '&:hover': {
            background: lighten(theme.palette.primary.main, 0.2),
        },
    },
}), { name: 'PhotoCropper' });

export interface PhotoCropperProps {
    orientationTypes?: PhotoOrientationType[];
    isDialogOpen: boolean;
    imageURI: string;
    isSaving?: boolean;
    activePhoto?: GatherPhoto;
    closeDialog: () => void;
    onSaveImage: (
        transformations: PhotoTransformationsType,
        newImageURI: string,
        coverPhotoType?: CoverPhotoEnum,
    ) => void;
    photoType?: PhotoTypeEnum;
    hasTransformations?: boolean;
    initialTransformations?: CloudinaryTransformationsType;
    zIndex: number;
    coverPhotoType?: CoverPhotoEnum;
}

const PhotoCropper = (props: PhotoCropperProps) => {
    const {
        isDialogOpen,
        orientationTypes,
        imageURI,
        activePhoto,
        closeDialog,
        onSaveImage,
        photoType,
        zIndex,
        hasTransformations,
        initialTransformations,
        coverPhotoType,
        isSaving,
    } = props;

    const classes = useStyles();


    const stencilType = photoType === PhotoTypeEnum.user_profile
        ? CropperStencilEnum.circle : CropperStencilEnum.rectangle;
    const [crop, setCrop] = useState<CloudinaryTransformationsType | null>(null);
    const [isRevertClicked, setIsRevertClicked] = useState(false);
    const [revision, setRevision] = useState(0);
    const [cropperRef, setCropperRef] = useState<CropperRef | null>(null);

    const handleRevertCrop = () => {
        setRevision(revision + 1);
        setCrop(null);
        setIsRevertClicked(true);
    };

    const handleCloseDialogEvent = () => {
        closeDialog();
    };

    const getNewDataURI = () => {
        if (!cropperRef) {
            return imageURI;
        }
        const canvas = cropperRef.getCanvas();
        return canvas ? canvas.toDataURL() : imageURI;
    };

    const handleSaveImageClick = () => {
        if (hasTransformations) {
            onSaveImage({ cloudinary: crop || undefined } || {}, imageURI);
        } else {
            onSaveImage({}, getNewDataURI());
        }
    };

    return (
        <Dialog
            open={isDialogOpen}
            onClose={handleCloseDialogEvent}
            fullScreen={false}
            TransitionComponent={SlideTransition}
            transitionDuration={300}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            classes={{
                root: classes.root,
                paper: classes.cropperDialog
            }}
            style={{ zIndex }}
        >
            <BasePhotoCropper
                isRevertClicked={isRevertClicked}
                imageURI={imageURI}
                activePhoto={activePhoto}
                stencilType={stencilType}
                orientation={orientationTypes && orientationTypes[0]}
                coverPhotoType={coverPhotoType}
                cropChanged={(c, r) => {
                    setCrop(c);
                    setIsRevertClicked(false);
                    setCropperRef(r);
                }}
                initialTransformations={initialTransformations}
                revision={revision}
            />
            <Grid item xs={12} className={classes.dialogFooter}>
                <GButton
                    className={classes.revertButton}
                    onClick={() => handleRevertCrop()}
                >
                    Revert
                </GButton>
                <GButton
                    className={classes.revertButton}
                    onClick={() => closeDialog()}
                    disabled={isSaving}
                >
                    Cancel
                </GButton>
                <GButton
                    className={classes.saveButton}
                    onClick={handleSaveImageClick}
                    disabled={isSaving}
                >
                    Save
                </GButton>
            </Grid>

        </Dialog>
    );
};

export default PhotoCropper;