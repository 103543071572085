import { useCallback, useRef, useState } from 'react';
import classNames from 'classnames';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import FavoriteIcon from '@mui/icons-material/Favorite';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import { registerAppError } from '../../../../../actions/errors';
import { loginAndSaveMemory } from '../../../../../actions/GatherCase.action';
import { createMemory } from '../../../../../actions/Memory.action';
import { updateVisitorPhoto } from '../../../../../actions/visitor.action';
import { getDataURIFromFile } from '../../../../../services';
import {
    GatherCasePublic,
    MemoryPrompt,
    MemoryUX,
    PhotoTransformationsType,
    PhotoTypeEnum,
    ThemeUX,
    UserProfile
} from '../../../../../shared/types';
import { useGDispatch, useGSelector } from '../../../../../types';
import FileUploader, { AcceptFileType } from '../../../../common/FileUploader';
import UserAvatar from '../../../../common/UserAvatar';
import PhotoCropper from '../../../../profileImage/PhotoCropper';
import { performCasePhotoUpload, createUploadFromDispatch } from '../../../../profileImage/utility';
import { PostMemoryParams } from '../../memories/Memories';
import MemoryContributionList from '../../memories/postMemories/MemoryContributionList';
import { SelfLoginStyledProps } from '../SelfLogin.styles';
import { SelfLoginSrcAction } from '../SelfLoginDialog';
import GButton from '../../../../common/GButton';
import { ShareOptionType } from '../../widgets/ShareLinkSection';
import CandleMessageDialog from '../../memories/postMemories/CandleMessage.dialog';
import { createSaveMemoryPromise } from '../../memories/postMemories/MemoryContribution';
import { NotificationMethod, SubscriptionType } from '../../../../../shared/types/subscription';
import { updateCaseEntitySubscription } from '../../../../../actions/Subscription.action';

interface UseMemoryUploadParams {
    gatherCase: GatherCasePublic;
    srcAction: SelfLoginSrcAction;
    zIndex: number;
}

export const useMemoryUpload = ({ gatherCase, zIndex, srcAction }: UseMemoryUploadParams) => {
    const dispatch = useGDispatch();
    const userData = useGSelector(({ userSession }) => userSession.userData);

    const postMemory = useCallback(async ({ memoryText, memoryPrompt, callback }: PostMemoryParams) => {
        if (!memoryText || !memoryText.trim()) {
            callback(false, true);
            return;
        }

        const newMemory = await dispatch(createMemory(gatherCase.name, memoryText, memoryPrompt));
        callback(false, newMemory === null);
    }, [dispatch, gatherCase.name]);

    const processFileUpload = useCallback(async ({ files, callback }: PostMemoryParams) => {
        await performCasePhotoUpload(files, gatherCase, createUploadFromDispatch(dispatch));
        callback(false);
    }, [dispatch, gatherCase]);

    const checkLoginAndPostMemory = useCallback(async (params: PostMemoryParams) => {
        const { srcAction: paramSrcAction, callback } = params;

        let onLoginSuccess: (() => void) | null = null;

        if (paramSrcAction === SelfLoginSrcAction.shareText) {
            onLoginSuccess = () => postMemory(params);
        } else if (paramSrcAction === SelfLoginSrcAction.sharePhoto) {
            onLoginSuccess = () => processFileUpload(params);
        }

        await dispatch(loginAndSaveMemory({
            user: userData,
            publicCase: gatherCase,
            zIndex: zIndex + 1,
            srcAction,
            onLoginSuccess,
            onAbort: () => callback(false),
            onClose: () => callback(false)
        }));
    }, [dispatch, gatherCase, postMemory, processFileUpload, srcAction, userData, zIndex]);

    return checkLoginAndPostMemory;
};

interface Props extends SelfLoginStyledProps {
    publicCase: GatherCasePublic;
    userData: UserProfile | null;
    srcAction: SelfLoginSrcAction;
    photosToUploadCount: number | null;
    memoryList: MemoryUX[];
    zIndex: number;
    activeTheme: ThemeUX | null;
    gaClassName?: string;
    onCloseDialog: () => void;
    onSocialIconPopperOpen: (
        event: React.MouseEvent<HTMLElement, MouseEvent>,
        shareOptionType: ShareOptionType,
        timeout?: number | undefined
    ) => void;
    onSocialIconPopperClose: () => void;
    onShareOptionsDialogOpen: () => void;
}

const PostCommentsStep = (props: Props) => {
    const {
        publicCase,
        classes,
        userData,
        srcAction,
        photosToUploadCount,
        memoryList,
        zIndex,
        activeTheme,
        gaClassName,
        onCloseDialog,
        onSocialIconPopperClose,
        onSocialIconPopperOpen,
        onShareOptionsDialogOpen
    } = props;

    const dispatch = useGDispatch();

    const [profilePhoto, setProfilePhoto] = useState('');
    const [photoCropperOpen, setPhotoCropperOpen] = useState(false);
    const [saving, setSaving] = useState(false);

    const [notifyOnNewMemories, setNotifyOnNewMemories] = useState(true);
    const [notifyServiceReminders, setNotifyServiceReminders] = useState(true);

    const [candleMessageDialogOpen, setCandleMessageDialogOpen] = useState(false);
    const [candleMemorySaving, setCandleMemorySaving] = useState(false);

    const memoryPromptAnswers = useRef<{ prompt: MemoryPrompt; answer: string }[]>([]);

    const postMemory = useMemoryUpload({
        gatherCase: publicCase,
        zIndex,
        srcAction: SelfLoginSrcAction.shareText
    });

    const handleSubscriptionChange = async (args: { subtype: SubscriptionType; notify: boolean }) => {
        const { subtype, notify } = args;
        if (!userData || !userData.entity_id || !userData.cases || !publicCase || !publicCase.id) {
            return;
        }
        if (subtype === SubscriptionType.case_service_reminder) {
            setNotifyServiceReminders(notify);
        } else if (subtype === SubscriptionType.case_new_memories) {
            setNotifyOnNewMemories(notify);
        } else {
            return; // not supported
        }
        // the UI currently does not allow the user to switch beteen email and sms at this point
        const notifyMethod = userData.email ? NotificationMethod.email: NotificationMethod.sms; 

        const thisSub = userData.cases.find(ce => ce.gather_case_id === publicCase.id)?.subscriptions.find(
            s => {
                return (s.subscription_type === subtype && s.notification_method === notifyMethod);
            });

        if (!thisSub) {
            return;
        }
        if (subtype === SubscriptionType.case_service_reminder) {
            const subscriptionsToProcess = userData.cases.find(ce =>
                ce.gather_case_id === publicCase.id)?.subscriptions.filter(
                    s => {
                        return (
                            s.subscription_type === SubscriptionType.case_service_summary
                            && s.notification_method === thisSub.notification_method
                            );
                    });
            if (subscriptionsToProcess) {
                for (const thisSubscription of subscriptionsToProcess) {
                    await dispatch(updateCaseEntitySubscription({
                        entityId: userData?.entity_id,
                        uuid: thisSubscription.uuid,
                        notify,
                    }));
                }
            }
        }
        const results = await dispatch(updateCaseEntitySubscription({
            entityId: userData?.entity_id,
            uuid: thisSub.uuid,
            notify,
        }));
        if (!results) {
            // error
            if (subtype === SubscriptionType.case_service_reminder) {
                setNotifyServiceReminders(!notify);
            } else if (subtype === SubscriptionType.case_new_memories) {
                setNotifyOnNewMemories(!notify);
            }
        }
    };

    const handleProfilePhotoSave = async (files: FileList | null) => {
        if (!files || !files.length) {
            return;
        }

        const dataURI = await getDataURIFromFile(files[0]);
        setProfilePhoto(dataURI);
        setPhotoCropperOpen(true);
    };

    const handleSaveProfileImage = async (
        transformations: PhotoTransformationsType,
        newImageURI: string,
    ) => {
        if (userData && profilePhoto) {
            const { entity_id: entityId } = userData;
            dispatch(updateVisitorPhoto(entityId, profilePhoto, transformations));
        } else {
            dispatch(registerAppError('Unable to update photo'));
        }
        setPhotoCropperOpen(false);
    };

    const saveMemoryPromptAnswers = useCallback((prompt: MemoryPrompt, answer: string) => {
        const existingMemoryObj = memoryPromptAnswers.current.find(object => object.prompt === prompt);

        memoryPromptAnswers.current = existingMemoryObj
            ? memoryPromptAnswers.current.map(object => object.prompt === prompt
                ? { prompt, answer }
                : object)
            : [...memoryPromptAnswers.current, { prompt, answer }];
    }, []);

    const isSharePhoto = srcAction === SelfLoginSrcAction.sharePhoto;
    const isGuestBookSignup = srcAction === SelfLoginSrcAction.signGuestbook;

    let headerText = 'Your message has been preserved.';       // memory/default text
    if (isGuestBookSignup) {
        headerText = 'Your name has been added.';              // guest book text
    } else if (isSharePhoto) {
        const photosText = photosToUploadCount !== 1
            ? 'photos have'
            : 'photo has';
        headerText = `Your ${photosText} been preserved.`;     // photo share text
    }

    const saveAllMemories = async () => {
        setSaving(true);

        // filtering memories if it already exists to make sure we aren't saving same twice
        const filteredMemories = memoryPromptAnswers.current.filter(memoryObject => {
            const existingMemory = memoryList.find(memory =>
                memory.prompt === memoryObject.prompt
                && memory.memory_text.toLowerCase() === memoryObject.answer.toLocaleLowerCase()
            );

            return !existingMemory;
        });

        if (!filteredMemories.length) {
            setSaving(false);
            onCloseDialog();
        }

        for (let i = 0; i < filteredMemories.length; i++) {
            const object = filteredMemories[i];

            if (object) {
                await postMemory({
                    memoryPrompt: object.prompt,
                    srcAction: SelfLoginSrcAction.shareText,
                    memoryText: object.answer,
                    files: null,
                    callback: () => {
                        if (i === (filteredMemories.length - 1)) {
                            setSaving(false);
                            onCloseDialog();
                        }
                    }
                });
            }
        }
        // save back all subscription preferences 


    };

    const submitCandleMemory = async (messageText: string) => {
        try {
            const { isSaving: memoryIsSaving, hasError: memoryHasError } = await createSaveMemoryPromise(
                MemoryPrompt.Candle,
                SelfLoginSrcAction.shareText,
                messageText,
                null,
                postMemory
            );

            setCandleMemorySaving(memoryIsSaving);
            setCandleMessageDialogOpen(memoryIsSaving || !!memoryHasError);
        } catch (error) {
            setCandleMemorySaving(false);
        }
    };

    return (
        <Grid
            item
            xs={12}
            className={classes.step4}
        >
            <Grid item xs={12} className={classes.profilePhotoHeading}>
                <Typography color="inherit" className={classes.messagePreserved}>
                    {headerText}
                </Typography>
                <Typography color="inherit" className={classes.thanksContributing}>
                    <FavoriteIcon color="inherit" />
                    &nbsp;Thank you for contributing&nbsp;
                    <FavoriteIcon color="inherit" />
                </Typography>
            </Grid>
            <Grid item xs={12} className={classes.profileHeader}>
                <Typography className={classes.topText} align="center">
                    {publicCase.display_fname}'s life was important to many people.
                </Typography>
                <Typography color="secondary" className={classes.helperTopText} align="center">
                    Share a few of your favorite memories of&nbsp;
                    <span>{publicCase.display_fname}</span>.
                </Typography>
            </Grid>

            <Grid item xs={12} className={classes.step4MiddleSection}>
                <Grid container maxWidth="1200px" className={classes.positionRelative}>
                    <MemoryContributionList
                        minimalView
                        zIndex={zIndex + 1}
                        publicCase={publicCase}
                        isUserPhotoSaving={false}
                        userData={userData}
                        memoryList={memoryList}
                        activeTheme={activeTheme}
                        postMemory={postMemory}
                        openSocialIconPopper={onSocialIconPopperOpen}
                        closeSocialIconPopper={onSocialIconPopperClose}
                        onFlowerSelect={onCloseDialog}
                        onAnswerChange={saveMemoryPromptAnswers}
                        onShareOptionsDialogOpen={onShareOptionsDialogOpen}
                        onCandleMemoryDialogOpen={() => setCandleMessageDialogOpen(true)}
                        gaClassName={gaClassName}
                    />
                </Grid>
            </Grid>

            <FileUploader
                acceptTypes={[AcceptFileType.PNG, AcceptFileType.GIF, AcceptFileType.JPEG]}
                handleSave={handleProfilePhotoSave}
            >
                {(inputRef) =>
                    <Grid item xs={12} className={classes.doneButtonContainer}>
                        {userData && userData.photo ?
                            <IconButton
                                className={classes.profileAvatarButton}
                                color="primary"
                                onClick={(e) => inputRef.click()}
                                size="large"
                            >
                                <UserAvatar
                                    user={userData}
                                    color="primary"
                                    className={classes.profileAvatarButton}
                                    size={120}
                                />
                            </IconButton>
                            : <div className={classes.uploadProfilePicContainer}>
                                <IconButton
                                    className={classes.profileAvatarButton}
                                    color="primary"
                                    onClick={(e) => inputRef.click()}
                                    size="large"
                                >
                                    {profilePhoto ?
                                        <img src={profilePhoto} alt="case-avatar" />
                                        : <AccountCircleIcon color="primary" />
                                    }
                                </IconButton>

                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={(e) => inputRef.click()}
                                >
                                    <AddAPhotoIcon color="primary" />
                                    &nbsp;Upload your profile photo
                                </Button>
                            </div>
                        }
                        <GButton
                            variant="contained"
                            color="primary"
                            text={saving ? 'Saving your memories...' : 'Continue'}
                            isSpinning={saving}
                            onClick={saveAllMemories}
                            className={classes.doneButton}
                        />
                    </Grid>
                }
            </FileUploader>

            <Grid item xs={12}>
                <Typography color="secondary" align="center" lineHeight="2">
                    Thank you for your support.
                </Typography>

                <Divider />

                <Grid container direction="column" maxWidth="400px" p="6px 0 10px">
                    <FormControlLabel
                        label={`I want to receive important updates & reminders about ${publicCase.fname}'s` +
                            ` services, such as LiveStream and event details.`}
                        control={<Checkbox color="secondary" className={classes.checkbox} />}
                        classes={{ label: classNames(classes.colorSecondary, classes.lineHeight1) }}
                        className={classes.margin_0}
                        checked={notifyServiceReminders}
                        onChange={(event, checked) => handleSubscriptionChange({
                            subtype: SubscriptionType.case_service_reminder,
                            notify: checked,
                        })}
                    />

                    <FormControlLabel
                        label={`I want to receive a recap of any new memories and photos shared to ` +
                            ` ${publicCase.fname}'s Remember page.`}
                        control={<Checkbox color="secondary" className={classes.checkbox} />}
                        classes={{ label: classNames(classes.colorSecondary, classes.lineHeight1) }}
                        className={classes.margin_0}
                        checked={notifyOnNewMemories}
                        onChange={(event, checked) => handleSubscriptionChange({
                            subtype: SubscriptionType.case_new_memories,
                            notify: checked,
                        })}
                    />
                </Grid>

                <Divider />

                <Typography
                    align="center"
                    lineHeight={2}
                    color="secondary"
                    className={classNames(classes.fontSize12, classes.marginTop2, classes.marginBottom16)}
                >
                    Your communication preferences can be adjusted at any time.
                </Typography>
            </Grid>
            <CandleMessageDialog
                zIndex={zIndex + 1}
                caseDisplayFname={publicCase.display_fname}
                showDialog={candleMessageDialogOpen}
                isSaving={candleMemorySaving}
                submitMemory={submitCandleMemory}
                closeDialog={() => setCandleMessageDialogOpen(false)}
            />

            <PhotoCropper
                imageURI={profilePhoto}
                onSaveImage={handleSaveProfileImage}
                photoType={PhotoTypeEnum.user_profile}
                hasTransformations
                isDialogOpen={photoCropperOpen}
                closeDialog={() => setPhotoCropperOpen(false)}
                zIndex={zIndex + 1}
            />
        </Grid>
    );
};

export default PostCommentsStep;