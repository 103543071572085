import AddIcon from '@mui/icons-material/Add';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { IntercomSVG } from '../svg/IntercomSVG';
import CallIcon from '@mui/icons-material/Call';
import SavedSearchIcon from '@mui/icons-material/SavedSearch';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Assignment from '@mui/icons-material/Assignment';
import LocalFloristIcon from '@mui/icons-material/LocalFlorist';
import TreeIcon from '@mui/icons-material/Park';
import { openHelperInvitationDialog, openSelfLoginDialog } from '../../../actions/Dialog.action';
import {
    FuneralHomeRoutePage,
    getFormattedPhoneNumber, RememberStoreType, RouteBuilder, showIntercom,
    urlIsFuneralHomeSite
} from '../../../services';
import { useGDispatch, useGSelector } from '../../../types';
import {
    EntityCaseRole,
    FeatureKey,
    getGatherCase,
    getGatherCaseName,
    ModerationCategory,
    RememberStoreCTA,
    UserRoles
} from '../../../shared/types';
import useFeatureEnabled from '../hooks/useFeatureEnabled';
import { setRolodexListDialogOpen } from '../../../actions/Rolodex.action';
import { useGNavigate } from '../hooks/useGNavigate';
import { useCallback, useState } from 'react';
import NotesDialog from '../../family/notes/NotesDialog';
import ModerationDialog from '../../moderation/ModerationDialog';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
import { SelfLoginSrcAction } from '../../family/remember/selfLogin/SelfLoginDialog';
import SendIcon from '@mui/icons-material/Send';
import ShareOptionsDialog from '../../family/remember/memories/postMemories/ShareOptions.dialog';
import { getRememberSettings, getShareOptions } from '../../family/remember/utils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare var Intercom: any;

enum SpeedDialAction {
    ChatWithGather = 'chat_with_gather',
    Call = 'call',
    CreateCase = 'create_case',
    GlobalSearch = 'global_search',
    ModerateMemories = 'moderate_memories',
    TaskNotes = 'task_notes',
    CaseNotes = 'case_notes',
    InviteGuest = 'invite_guest',
    Share = 'share',
    SignGuestBook = 'sign_guest_book',
    TreeStore = 'tree_store',
    FlowerStore = 'flower_store',
};
interface SpeedDialActionType {
    id: SpeedDialAction;
    name: string;
    icon: JSX.Element;
    count?: number;
    gaClassName?: string;
    callBack: () => void;
}


const useStyles = makeStyles((theme: Theme) => ({
    intercomIcon: {
        height: 24,
    },
    fillPrimary: {
        fill: `${theme.palette.primary.main} !important`
    },
}));

const useSpeedDialActions = (
    zIndex: number,
    isFamilyPage: boolean | undefined,
    isRememberPage: boolean | undefined
) => {
    const classes = useStyles();

    const dispatch = useGDispatch();
    const navigate = useGNavigate();

    const isCaseNotesEnabled = useFeatureEnabled(FeatureKey.CASE_NOTES);

    const userData = useGSelector(state => state.userSession.userData);
    const caseCounts = useGSelector(state => state.moderationState.caseCounts);
    const activeFuneralHome = useGSelector(state => state.funeralHomeState.activeFuneralHome);
    const noteState = useGSelector(state => state.noteState);
    const selectedCase = useGSelector(state => state.casesState.selectedCase);
    const publicCase = useGSelector(state => state.casesState.publicCase);

    const gatherCase = getGatherCase({ selectedCase, publicCase });
    const caseName = getGatherCaseName({ selectedCase, publicCase });
    const isFlowerLinkEnabled = useFeatureEnabled(FeatureKey.REMEMBER_FLOWER_LINK)
        && (gatherCase?.options.sell_flowers || false);
    const isTreeLinkEnabled = useFeatureEnabled(FeatureKey.PLANT_TREES)
        && (gatherCase?.options.plant_trees || false);

    const isFHorGOMUser = UserRoles.isFHorGOMUser(userData);
    const isFamilyVisitor = gatherCase && UserRoles.isFamilyVisitor(userData, gatherCase.id);

    const thisCaseCounts = gatherCase && caseCounts.find((c) => c.case_id === gatherCase.id);
    const pendingModerationItems = thisCaseCounts ? thisCaseCounts.pending.all : 0;
    const caseNotes = noteState.caseNotes;
    const taskNotes = noteState.taskNotes;

    const gatherMobile = '2089080488';
    const funeralMobile = activeFuneralHome?.phone || gatherCase?.funeral_home.phone;
    const gatherOrFhMobileNumber = isFHorGOMUser ? gatherMobile : funeralMobile;

    const [notesDialogOpen, setNotesDialogOpen] = useState(false);
    const [moderationDialogOpen, setModerationDialogOpen] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);
    const [shareOptionsDialogOpen, setShareOptionsDialogOpen] = useState(false);

    const rememberSettings = getRememberSettings(gatherCase);
    const shareOptionSettings = getShareOptions(gatherCase);
    const isGuestBookEnabled = rememberSettings.guest_book.enabled;

    const renderShareOptions = shareOptionSettings
        && Object.keys(shareOptionSettings).some(o => shareOptionSettings[o].enabled);

    const openInviteDialog = () => {
        dispatch(openHelperInvitationDialog({
            zIndex: zIndex + 1,
            defaultTab: EntityCaseRole.guest,
        }));
    };

    const openIntercom = () => {
        Intercom('update', { 'hide_default_launcher': false });
        showIntercom();
    };

    const openNotesDialog = (_tabIndex: number) => {
        setNotesDialogOpen(true);
        setTabIndex(_tabIndex);
    };

    const closeNotesDialog = () => {
        setNotesDialogOpen(false);
    };

    const openModerationDialog = () => {
        setModerationDialogOpen(true);
    };

    const closeModerationDialog = useCallback(() => {
        setModerationDialogOpen(false);
    }, []);

    const openShareOptionsDialog = () => {
        setShareOptionsDialogOpen(true);
    };

    const closeShareOptionsDialog = useCallback(() => {
        setShareOptionsDialogOpen(false);
    }, []);

    const openRolodexListingDialog = () => {
        dispatch(setRolodexListDialogOpen(true));
    };

    const redirectToCall = (gatherOrFhMobileNumbr: string) => {
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = `tel:${gatherOrFhMobileNumbr}`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const openSelfLoginDialogGuestBook = () => {
        dispatch(openSelfLoginDialog({
            zIndex: zIndex + 1,
            srcAction: SelfLoginSrcAction.signGuestbook,
            onLoginSuccessCallback: null,
            onAbortCallback: null,
            onCloseCallback: null,
            photosToUploadCount: null
        }));
    };

    const funeralActions: SpeedDialActionType[] = [
        {
            id: SpeedDialAction.ChatWithGather,
            icon: <IntercomSVG themeFillClass={classes.fillPrimary} className={classes.intercomIcon} />,
            name: 'Chat with Us',
            callBack: openIntercom
        },
        {
            id: SpeedDialAction.Call,
            icon: <CallIcon />,
            name: gatherOrFhMobileNumber && getFormattedPhoneNumber(gatherOrFhMobileNumber) || '',
            callBack: () => gatherOrFhMobileNumber && redirectToCall(gatherOrFhMobileNumber)
        },
        {
            id: SpeedDialAction.CreateCase,
            icon: <AddIcon />,
            name: 'Create New Case',
            callBack: () => activeFuneralHome
                && navigate(RouteBuilder.FuneralHome(activeFuneralHome.key, FuneralHomeRoutePage.CREATECASE)),
        }
    ];
    if (!urlIsFuneralHomeSite()) {
        funeralActions.unshift({
            id: SpeedDialAction.GlobalSearch,
            icon: <SavedSearchIcon />,
            name: 'Global Search',
            callBack: openRolodexListingDialog
        });
    }
    const caseActions: SpeedDialActionType[] = [
        {
            id: SpeedDialAction.ModerateMemories,
            icon: <FavoriteIcon />,
            name: 'Moderate Memories',
            count: pendingModerationItems,
            callBack: openModerationDialog
        },
        {
            id: SpeedDialAction.TaskNotes,
            icon: <Assignment />,
            name: 'View Task Notes',
            count: taskNotes.length,
            callBack: () => openNotesDialog(1)
        },
        {
            id: SpeedDialAction.CaseNotes,
            icon: <AddIcon />,
            name: 'Add a Case Note',
            count: caseNotes.length,
            callBack: () => openNotesDialog(0)
        },
    ];
    const familyActions: SpeedDialActionType[] = [
        {
            id: SpeedDialAction.InviteGuest,
            icon: <PersonAddIcon color="primary" />,
            name: 'Invite Loved One',
            callBack: openInviteDialog
        },
        {
            id: SpeedDialAction.Share,
            icon: <SendIcon color="primary" />,
            name: 'Share this Page',
            callBack: openShareOptionsDialog,
        },
    ];
    if (isTreeLinkEnabled) {
        familyActions.splice(1, 0, {
            id: SpeedDialAction.TreeStore,
            icon: <TreeIcon color="primary" />,
            name: 'Plant a Memorial Tree',
            gaClassName: 'tree-speed-dial',
            callBack: () => caseName && navigate(RouteBuilder.RememberStore({
                caseName,
                cta: RememberStoreCTA.ObitToFamily,
                storeType: RememberStoreType.trees,
            }))
        });
    }
    if (publicCase?.options.sell_flowers) {
        familyActions.splice(1, 0, {
            id: SpeedDialAction.FlowerStore,
            icon: <LocalFloristIcon color="primary" />,
            name: 'Send Flowers',
            callBack: () => caseName && navigate(RouteBuilder.RememberStore({
                caseName,
                cta: RememberStoreCTA.ObitToFamily,
                storeType: RememberStoreType.flowers,
            }))
        });
    }

    const rememberActionsForLogoutUser: SpeedDialActionType[] = [
        {
            id: SpeedDialAction.Share,
            icon: <SendIcon />,
            name: 'Share this Page',
            callBack: openShareOptionsDialog,
        },
        {
            id: SpeedDialAction.SignGuestBook,
            icon: <PersonAddIcon />,
            name: gatherCase?.display_fname ? `Sign ${gatherCase.display_fname}'s Guestbook` : 'Sign Guestbook',
            callBack: openSelfLoginDialogGuestBook,
        },
    ];
    if (isTreeLinkEnabled) {
        rememberActionsForLogoutUser.push({
            id: SpeedDialAction.TreeStore,
            icon: <TreeIcon />,
            name: 'Plant a Memorial Tree',
            gaClassName: 'tree-speed-dial',
            callBack: () => caseName && navigate(RouteBuilder.RememberStore({
                caseName,
                cta: RememberStoreCTA.SpeedDial,
                storeType: RememberStoreType.trees,
            }))
        });
    }
    if (publicCase?.options.sell_flowers) {
        rememberActionsForLogoutUser.push({
            id: SpeedDialAction.FlowerStore,
            icon: <LocalFloristIcon />,
            name: 'Send Flowers',
            callBack: () => caseName && navigate(RouteBuilder.RememberStore({
                caseName,
                cta: RememberStoreCTA.SpeedDial,
                storeType: RememberStoreType.flowers,
            }))
        });
    }


    const speedDialActions = [];
    if (isFHorGOMUser) {
        for (const action of funeralActions) {
            if (!((action.id === SpeedDialAction.Call && action.name === '') ||
                (action.id === SpeedDialAction.CreateCase && (isFamilyPage || isRememberPage)))) {
                speedDialActions.push(action);
            }
        }
    }

    if ((isFamilyPage || isRememberPage) && isFHorGOMUser) {
        for (const actions of caseActions) {
            if (!isCaseNotesEnabled
                && (actions.id === SpeedDialAction.CaseNotes || actions.id === SpeedDialAction.TaskNotes)) {
                continue;
            }
            speedDialActions.push(actions);
        }
    }

    if (!isFHorGOMUser && userData !== null) {
        for (const action of familyActions) {
            if ((!isFlowerLinkEnabled && action.id === SpeedDialAction.FlowerStore)
                || (action.id === SpeedDialAction.ChatWithGather && isFamilyVisitor)
                || (action.id === SpeedDialAction.Share && (!publicCase || !renderShareOptions))
                || (action.id === SpeedDialAction.InviteGuest && !selectedCase)) {
                continue;
            }
            speedDialActions.push(action);
        }
    }

    if (isRememberPage && userData === null) {
        for (const action of rememberActionsForLogoutUser) {
            // push all the items except for 'send flowers' item and 
            // check if item === 'send flowers' then isFlowersLink must be true
            if ((action.id === SpeedDialAction.FlowerStore && !isFlowerLinkEnabled)
                || (action.id === SpeedDialAction.Share && !renderShareOptions)
                || (action.id === SpeedDialAction.SignGuestBook && !isGuestBookEnabled)) {
                continue;
            }
            speedDialActions.push(action);
        }
    }

    return {
        speedDialActions,
        activeFuneralHome,
        selectedCase,
        publicCase,
        notesDialogOpen,
        closeNotesDialog,
        moderationDialogOpen,
        closeModerationDialog,
        tabIndex,
        shareOptionsDialogOpen,
        closeShareOptionsDialog,
    };
};

interface Props {
    zIndex: number;
    isFamilyPage?: boolean;
    isRememberPage?: boolean;
};

export interface WithSpeedDialActionsProps {
    speedDialActions: SpeedDialActionType[];
}

export const withSpeedDialActions = <P extends object>(
    Component: React.ComponentType<P & WithSpeedDialActionsProps>
) => React.memo(function WithSpeedDialActions(props: P & Props) {
    const {
        speedDialActions,
        selectedCase,
        publicCase,
        notesDialogOpen,
        closeNotesDialog,
        moderationDialogOpen,
        closeModerationDialog,
        tabIndex,
        shareOptionsDialogOpen,
        closeShareOptionsDialog
    } = useSpeedDialActions(props.zIndex, props.isFamilyPage, props.isRememberPage);

    return (
        <>
            <Component
                {...props}
                speedDialActions={speedDialActions}
            />

            {selectedCase &&
                <NotesDialog
                    caseUuid={selectedCase.uuid}
                    gatherCaseFname={selectedCase.fname}
                    isDialogOpen={notesDialogOpen}
                    closeDialog={closeNotesDialog}
                    zIndex={props.zIndex + 2}
                    tabIndex={tabIndex}
                />
            }
            {selectedCase && <ModerationDialog
                isOpen={moderationDialogOpen}
                zIndex={props.zIndex + 1}
                handleClose={closeModerationDialog}
                initialCategory={ModerationCategory.visitors}
            />}
            {publicCase && <ShareOptionsDialog
                isOpen={shareOptionsDialogOpen}
                closeDialog={closeShareOptionsDialog}
                publicCase={publicCase}
                zIndex={props.zIndex + 1}
            />}
        </>
    );
});